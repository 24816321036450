import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import utils from '../utils/utils';
import { Helmet } from 'react-helmet';
import FirebaseAPIs from '../handlers/firebaseAPIs';

interface ICurrentProps {}

interface ICurrentState {
  username: string;
  password: string;
  error: string;
  isForgotPasswordLayout: boolean;
}

export default function Login(_props: ICurrentProps) {
  const [state, setState] = React.useState<ICurrentState>({
    username: '',
    password: '',
    error: '',
    isForgotPasswordLayout: false,
  });

  async function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    const email: string = state.username;
    const password: string = state.password;
    const isForgotPasswordMode: boolean = state.isForgotPasswordLayout;

    try {
      if (isForgotPasswordMode) {
        await FirebaseAPIs.sendResetPasswordEmail(email);
        alert(`Please check your inbox for further instructions`);
        setState(prev => ({
          ...prev,
          isForgotPasswordLayout: false,
        }));
      } else {
        const user = await FirebaseAPIs.login(email, password).catch(err1 => {
          console.error('ERROr1: ' + err1);
          setState(prev => ({
            ...prev,
            error: '' + err1,
          }));
        });
      }
      // console.log(`user=${JSON.stringify(user,null,2)}`);
    } catch (err2) {
      console.error(`ERROr2: ${utils.convertToString(err2)}`);
      const message =
        err2 && typeof err2 === 'object' && 'message' in err2
          ? (err2 as { message: string }).message
          : utils.convertToString(err2);
      setState(prev => ({
        ...prev,
        error: message,
      }));
    }
  }

  function emailChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setState(prev => ({ ...prev, username: event.target.value }));
  }

  function passwordChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setState(prev => ({ ...prev, password: event.target.value }));
  }

  return (
    <div style={divStyle}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {/* for="phone" */}
      <form>
        <label>Email</label>
        <br />
        <br />
        <input type="text" onChange={emailChangeHandler} placeholder="user@domain.com" />
        <br />
        <br />
        <label hidden={state.isForgotPasswordLayout}>Password</label>
        <br />
        <br />
        <input
          type="password"
          onChange={passwordChangeHandler}
          hidden={state.isForgotPasswordLayout}
          placeholder="Password"
        />
        <br />
        <br />
        <button onClick={handleClick} disabled={!state.username || !(state.isForgotPasswordLayout || state.password)}>
          {state.isForgotPasswordLayout ? 'Send a reset-password email' : 'Login'}
        </button>
        <br />
        <a
          style={linkStyle}
          hidden={state.isForgotPasswordLayout}
          onClick={() => {
            setState(prev => ({
              ...prev,
              isForgotPasswordLayout: true,
            }));
          }}
        >
          Forgot password?
        </a>
        <br />
        <a
          style={linkStyle}
          onClick={() => {
            setState(prev => ({ ...prev, isForgotPasswordLayout: false }));
          }}
          hidden={!state.isForgotPasswordLayout}
        >
          I remember the password!
        </a>
        <br />
        <br />
        <a style={linkStyle} href="/">
          Back to homepage
        </a>
        <p style={{ color: 'red' }}>{state.error}</p>
      </form>
    </div>
  );
}

const divStyle: React.CSSProperties = {
  textAlign: 'center',
  marginTop: '2%',
};

const linkStyle: React.CSSProperties = {
  cursor: 'pointer',
  color: 'blue',
  fontSize: 'x-small',
};
