exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ad-details-tsx": () => import("./../../../src/pages/Ad-details.tsx" /* webpackChunkName: "component---src-pages-ad-details-tsx" */),
  "component---src-pages-admin-jobs-tsx": () => import("./../../../src/pages/admin_jobs.tsx" /* webpackChunkName: "component---src-pages-admin-jobs-tsx" */),
  "component---src-pages-admin-sessions-tsx": () => import("./../../../src/pages/admin_sessions.tsx" /* webpackChunkName: "component---src-pages-admin-sessions-tsx" */),
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin_users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */)
}

